import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {Banner,mapToPageHeader} from "@components/Banner"
import {SEO,mapToSEO} from '@components/SEO'
import { SignupBannerWrapper,mapToKiwiVIPVisible} from "@components/SignupBanner";
import { mapPlateStorySummaryList } from "@mappers/PlateStories/mapper"
import PlateStoryList from "@components/PlateStoryList/PlateStoryList"


const PlateStories = (props:any) => {

    const {nodes} = props.data.allKontentItemPlateStoryPage;
    const {elements} = props.data.kontentItemPlateStoriesLandingPage;
    const seo = mapToSEO(elements);
    const kiwiVip = mapToKiwiVIPVisible(elements);
    const metadata = mapToPageHeader(elements);
    const plateStoriesData = mapPlateStorySummaryList(nodes);
    const filters = elements.plate_story_category.value.map((filter:any) => {return filter.name});

    return (
        <Layout location={props.location}>              
            <SEO {...seo} />
            <Banner {...metadata} isBiggerBanner={false}/>
            <PlateStoryList title={elements.heading.value} stories={plateStoriesData} filters={filters} />
            {
                kiwiVip.visible && 
                <SignupBannerWrapper version= {kiwiVip.version} />
            }
        </Layout>);
  
}

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  allKontentItemPlateStoryPage {
          nodes {
              elements {
                main_plate_story_landing_page_image{
                  value{
                    url
                    description
                  }
                }
                landing_page_featured_image {
                  value {
                    url
                    description
                  }
                }
                featured_image {
                  value {
                      url
                      description
                    }
                }
                  body {
                      value
                  }
                  url {
                      value
                      }
                  is_featured {
                      value {
                          name
                          codename
                      }
                  }
                  plate_story_category {
                      value {
                          codename
                          name
                      }
                  }
                  image_gallery {
                    value {
                          url
                      }
                  }
                  summary {
                      value
                  }
                  youtube_video_url {
                      value
                  }
              }
              system {
                  name
              }
          }
      }
    kontentItemPlateStoriesLandingPage {
      elements {
        plate_story_category {
          value {
            name
          }
        }
        general_page_content__header_image {
          value {
            url
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            codename
            name
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            codename
            name
          }
        }
        general_page_content__title {
          value
        }
        general_page_content__title_position {
          value {
            codename
            name
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url {
          value
        }
      }
    }
  }

`
export default PlateStories;