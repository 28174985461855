import React from 'react'
import { Grid, Segment, Responsive } from 'semantic-ui-react'
import { KpButton } from "@elements/index"
import { PlateStorySummary } from '@models/PlateStories/PlateStory'
import { PlateStoryListContainer, PlateStoryListFilter, PlateStoryImageContainer, PlateStoryFilterContainer, PlateStoryHeaderContainer, ViewMoreButtonContainer, PlateStorySegment } from './PlateStoryList.styles'
import { H1, H4 } from '@styles/Global.styles'
import theme from '@styles/theme'
import { Link } from 'gatsby'

interface PlateStoryListProps {
    title: string,
    stories: PlateStorySummary[],
    filters: string[]
}

interface PlateStoryListState {
    filteredStories: PlateStorySummary[]
    activeFilters: string[],
    itemsToShow: number
}


class PlateStoryList extends React.Component<PlateStoryListProps, PlateStoryListState>{
    constructor(props: PlateStoryListProps) {
        super(props)
        this.state = {
            filteredStories: this.props.stories,
            activeFilters: [],
            itemsToShow: 4
        }
    }

    viewMoreItems = () => {
        let currentItemsToShow = this.state.itemsToShow;
        this.setState({
            itemsToShow: currentItemsToShow += 4
        })
    }

    applyFilter = (filter: string) => {

        let currentFilters: string[] = this.state.activeFilters;

        if (this.state.activeFilters.indexOf(filter) !== -1) {
            currentFilters.splice(currentFilters.indexOf(filter), 1);
        }
        else {
            currentFilters.push(filter);
        }

        let filteredPlateStories: PlateStorySummary[] = this.props.stories.filter((story) => {
            return story.categories.every(category => (currentFilters.indexOf(category) !== -1));
        });

        this.setState({
            activeFilters: currentFilters,
            filteredStories: currentFilters.length === 0 ? this.props.stories : filteredPlateStories
        });
    }

    resetFilters = () => {
        this.setState({
            activeFilters: [],
            filteredStories: this.props.stories
        })
    }

    render() {
        const { title, filters, stories } = this.props;
        const { filteredStories, activeFilters } = this.state;

        let featuredPlateStoryIndex = -1
        this.state.filteredStories.forEach((story, index) => {
            if (story.isFeatured) {
                featuredPlateStoryIndex = index;
            }
        });

        return (
            <PlateStorySegment darkTheme={false} basic>
                <PlateStoryListContainer textAlign='center'>
                    <PlateStoryHeaderContainer>
                        <H1>{title}</H1>
                    </PlateStoryHeaderContainer>
                    <PlateStoryFilterContainer>
                        <PlateStoryListFilter className={this.state.activeFilters.length === 0 ? 'active' : ''} onClick={this.resetFilters}>Browse All</PlateStoryListFilter>
                        {
                            filters.map((filter, index) => {
                                return <PlateStoryListFilter className={this.state.activeFilters.indexOf(filter) !== -1 ? 'active' : ''} onClick={() => this.applyFilter(filter)} key={index}>{filter}</PlateStoryListFilter>
                            })
                        }
                    </PlateStoryFilterContainer>
                    <Grid width={16}>
                        {
                            featuredPlateStoryIndex !== -1 && activeFilters.length === 0 &&
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Link to={stories[featuredPlateStoryIndex].url} aria-label={stories[featuredPlateStoryIndex].bannerFeaturedImage?.description}>

                                        {
                                            stories[featuredPlateStoryIndex].pageFeaturedImage !== undefined &&
                                            <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                                <PlateStoryImageContainer className='featured'
                                                    alt={stories[featuredPlateStoryIndex].pageFeaturedImage?.description}
                                                    backgroundUrl={stories[featuredPlateStoryIndex].pageFeaturedImage?.url}
                                                    verticalAlign='bottom' textAlign='left'>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <H4 color={theme.brand.colors.white}>{stories[featuredPlateStoryIndex].title}</H4>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </PlateStoryImageContainer>
                                            </Responsive>
                                        }
                                        {
                                            stories[featuredPlateStoryIndex].landingFeaturedImage !== undefined &&
                                            <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.desktop.maxWidth}>
                                                <PlateStoryImageContainer className='featured'
                                                    alt={stories[featuredPlateStoryIndex].landingFeaturedImage?.description}
                                                    backgroundUrl={stories[featuredPlateStoryIndex].landingFeaturedImage?.url}
                                                    verticalAlign='bottom' textAlign='left'>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <H4 color={theme.brand.colors.white}>{stories[featuredPlateStoryIndex].title}</H4>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </PlateStoryImageContainer>
                                            </Responsive>
                                        }
                                        <Responsive minWidth={theme.responsive.widescreen.minWidth}>
                                            <PlateStoryImageContainer className='featured'
                                                alt={stories[featuredPlateStoryIndex].bannerFeaturedImage?.description}
                                                backgroundUrl={stories[featuredPlateStoryIndex].bannerFeaturedImage !== undefined ? stories[featuredPlateStoryIndex].bannerFeaturedImage?.url : stories[featuredPlateStoryIndex].landingFeaturedImage?.url}
                                                verticalAlign='bottom' textAlign='left'>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <H4 color={theme.brand.colors.white}>{stories[featuredPlateStoryIndex].title}</H4>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </PlateStoryImageContainer>
                                        </Responsive>
                                    </Link>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            {
                                this.state.filteredStories.map((story, index) => {
                                    return index < this.state.itemsToShow && (activeFilters.length > 0 || index !== featuredPlateStoryIndex) &&
                                        <Grid.Column key={story.title} mobile={16} tablet={8} computer={8} padded='vertically'>
                                            <Link to={story.url} aria-label={story.landingFeaturedImage?.description}>
                                                <Responsive maxWidth={theme.responsive.mobile.maxWidth}>
                                                    <PlateStoryImageContainer alt={story.pageFeaturedImage?.description}
                                                        backgroundUrl={story.pageFeaturedImage?.url}
                                                        verticalAlign='bottom' textAlign='left'>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <H4 color={theme.brand.colors.white}>{story.title}</H4>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </PlateStoryImageContainer>
                                                </Responsive>
                                                <Responsive minWidth={theme.responsive.tablet.minWidth} maxWidth={theme.responsive.desktop.maxWidth}>
                                                    <PlateStoryImageContainer alt={story.pageFeaturedImage?.description}
                                                        backgroundUrl={story.pageFeaturedImage?.url}
                                                        verticalAlign='bottom' textAlign='left'>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <H4 color={theme.brand.colors.white}>{story.title}</H4>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </PlateStoryImageContainer>
                                                </Responsive>
                                                <Responsive minWidth={theme.responsive.widescreen.minWidth}>
                                                    <PlateStoryImageContainer alt={story.landingFeaturedImage?.description}
                                                        backgroundUrl={story.landingFeaturedImage?.url}
                                                        verticalAlign='bottom' textAlign='left'>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <H4 color={theme.brand.colors.white}>{story.title}</H4>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </PlateStoryImageContainer>
                                                </Responsive>
                                            </Link>
                                        </Grid.Column>
                                })
                            }
                        </Grid.Row>
                        <Grid.Row textAlign='center'>
                            <ViewMoreButtonContainer>
                                {
                                    filteredStories.length >= this.state.itemsToShow &&
                                    <KpButton id="pslmore" onClick={this.viewMoreItems} buttonType='secondary' color={theme.brand.colors.blue}>VIEW MORE</KpButton>
                                }

                            </ViewMoreButtonContainer>
                        </Grid.Row>
                    </Grid>
                </PlateStoryListContainer>
            </PlateStorySegment>
        )
    }
}
export default PlateStoryList