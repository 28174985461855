import React from 'react'
import styled from "@emotion/styled";
import theme from "@styles/theme";
import { Container, Grid, GridColumn, Segment } from "semantic-ui-react";
import mq from '@styles/mq'

export const PlateStoryListContainer = styled(Container)`
`

export const PlateStoryListFilter = styled.span`
    cursor: pointer;

    ${mq({
        margin: [ '0 5px','0 15px','0 15px']
    })}

    &.active {
        color: ${theme.brand.colors.orange};
        border-bottom: 2px solid ${theme.brand.colors.orange};
    }
`

export const ViewMoreButtonContainer= styled(GridColumn)`
    margin: 20px 0 40px 0 !important;
`

export const PlateStoryFilterContainer = styled.div`
    margin-bottom: 20px;
`

export const PlateStoryHeaderContainer = styled.div`
    margin: 40px 0;
`

export const PlateStoryImageContainer = styled(({backgroundUrl, ...others})=><Grid {...others} />)`
    border-radius: 8px;
    background-image: url("${props => props.backgroundUrl}");
    ${mq({
        height: [ '60vw','220px','300px']
    })}
    background-size: cover;
    box-shadow: 0px 3px 6px #00000029;
    padding: 20px 30px !important;
    margin-left: 0 !important;
    margin-right: 0!important;
    margin-top: 0!important;
    margin-bottom: 30px!important;

    &.featured {
        height: styl;
        ${mq({
            padding:[ '30px 30px 45px 30px','30px 30px 45px 30px'],
            height: [ '60vw','300px','402px']
        })}
        margin-bottom: 0!important;
    }
`
export const PlateStorySegment = styled(({darkTheme, ...rest}) => <Segment {...rest} />)`
 background-color: ${p => p.darkTheme ? 'black !important' : 'white !important'};
`